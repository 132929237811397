.hero-image {
    background-image: url("https://images.unsplash.com/photo-1598103586054-c4b456cbddc4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80"); 
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
}

#heroName {
    font-family: 'Cedarville Cursive', cursive;
}

#heroDescription {
    color:rgb(45, 45, 45);
    font-family: 'Cedarville Cursive', cursive;
}