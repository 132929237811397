.TechItem {
    font-weight: bold;
}

#CenterText {
    text-align: center;
}

#TitleSubText {
    text-align: left;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#Tech {
    font-family: 'Orbitron', sans-serif;
}

#Title {
    font-family: 'Lobster', cursive;
}

#IphoneImageContainer {
    margin-left: auto;
    margin-right: auto;
}

#ImageContainer {
    margin: auto;
}